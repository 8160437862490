import {
  Board,
  BoardActive,
  Fire,
  FishingIconM,
  FishingIconME,
  LiveIconM,
  LiveIconME,
  LotteryIconM,
  LotteryIconME,
  SlotIconM,
  SlotIconME,
  SportIconM,
  SportIconME,
} from "assets/Mobile/icons";
import BgFishingMobile from "assets/temporary/images/bg_fishingSM.jpg";
import BgLiveMobile from "assets/temporary/images/bg_liveSM.jpg";
import BgLotteryMobile from "assets/temporary/images/bg_lotterySM.jpg";
import BgSLotMobile from "assets/temporary/images/bg_slotsSM.jpg";
import BgSportMobile from "assets/temporary/images/bg_sportSM.jpg";
import BgFishingDesktop from "assets/temporary/images/bn_fishingXL.jpg";
import BgLiveDesktop from "assets/temporary/images/bn_liveXL.jpg";
import BgLotteryDesktop from "assets/temporary/images/bn_lotteryXL.jpg";
import BgSLotDesktop from "assets/temporary/images/bn_slotXL.jpg";
import BgSportDesktop from "assets/temporary/images/bn_sportXL.jpg";
import { GameTypeEnum } from "enums/game";

import { PATH } from "./path";

export interface CategoryGameType {
  id: string;
  name: string;
  icon: string;
  iconSelected: string;
  path: string;
  backgroundMobile?: string;
  backgroundDesktop?: string;
  disableMobile?: boolean;
}

export const LIST_CATEGORY_GAME: CategoryGameType[] = [
  {
    id: "H",
    name: "Home",
    icon: "",
    iconSelected: "",
    path: PATH.Home,
    backgroundDesktop: "",
    backgroundMobile: "",
    disableMobile: true,
  },
  {
    id: GameTypeEnum.LiveCasino,
    name: "Live",
    iconSelected: LiveIconM,
    path: PATH.liveGamePage,
    icon: LiveIconME,
    backgroundMobile: BgLiveMobile,
    backgroundDesktop: BgLiveDesktop,
  },
  {
    id: GameTypeEnum.Slot,
    name: "Slots",
    iconSelected: SlotIconM,
    path: PATH.slotGamePage,
    icon: SlotIconME,
    backgroundMobile: BgSLotMobile,
    backgroundDesktop: BgSLotDesktop,
  },
  {
    id: GameTypeEnum.Sport,
    name: "Sports",
    icon: SportIconME,
    path: PATH.sportGamePage,
    iconSelected: SportIconM,
    backgroundMobile: BgSportMobile,
    backgroundDesktop: BgSportDesktop,
  },
  {
    id: GameTypeEnum.Fishing,
    name: "Fishing",
    icon: FishingIconME,
    path: PATH.fishingGamePage,
    iconSelected: FishingIconM,
    backgroundMobile: BgFishingMobile,
    backgroundDesktop: BgFishingDesktop,
  },

  {
    id: GameTypeEnum.ESport,
    name: "E-Sports",
    icon: LotteryIconME,
    path: PATH.boardGamePage,
    iconSelected: LotteryIconM,
    backgroundMobile: BgLotteryMobile,
    backgroundDesktop: BgLotteryDesktop,
  },
];

export const listCategoryMobile: CategoryGameType[] = [
  {
    id: "HT",
    name: "HotGame",
    icon: Fire,
    path: "/hot",
    iconSelected: Fire,
  },
  {
    id: GameTypeEnum.Slot,
    name: "Slots",
    iconSelected: SlotIconM,
    path: PATH.slotGamePage,
    icon: SlotIconME,
    backgroundMobile: BgSLotMobile,
    backgroundDesktop: BgSLotDesktop,
  },
  {
    id: GameTypeEnum.LiveCasino,
    name: "Live",
    iconSelected: LiveIconM,
    path: PATH.liveGamePage,
    icon: LiveIconME,
    backgroundMobile: BgLiveMobile,
    backgroundDesktop: BgLiveDesktop,
  },
  {
    id: GameTypeEnum.Sport,
    name: "Sports",
    icon: SportIconME,
    path: PATH.sportGamePage,
    iconSelected: SportIconM,
    backgroundMobile: BgSportMobile,
    backgroundDesktop: BgSportDesktop,
  },
  {
    id: GameTypeEnum.Fishing,
    name: "Fishing",
    icon: FishingIconME,
    path: PATH.fishingGamePage,
    iconSelected: FishingIconM,
    backgroundMobile: BgFishingMobile,
    backgroundDesktop: BgFishingDesktop,
  },
  {
    id: GameTypeEnum.ESport,
    name: "E-Sports",
    icon: Board,
    path: PATH.boardGamePage,
    iconSelected: BoardActive,
  },
];
