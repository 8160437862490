/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Empty, Modal } from "antd";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { PromotionType } from "types/deposit";

import { ModalDepositStyle } from "./index.style";

interface Props {
  promotions: PromotionType[];
  promotionId: string;
  isOpenModalPromotion: boolean;
  setPromotionId: (id: string) => void | Dispatch<SetStateAction<string>>;
  setPromotionName: (name: string) => void | Dispatch<SetStateAction<string>>;
  setIsOpenModalPromotion: Dispatch<SetStateAction<boolean>>;
}

export const ModalPromotion = (props: Props) => {
  const {
    promotions,
    promotionId,
    isOpenModalPromotion,
    setPromotionId,
    setPromotionName,
    setIsOpenModalPromotion,
  } = props;

  const { t } = useTranslation(["deposit", "button", "empty"]);
  return (
    <Modal
      open={isOpenModalPromotion}
      onCancel={() => setIsOpenModalPromotion(false)}
      centered
      css={ModalDepositStyle.modal}
      footer={null}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {t("ChoosePromotion")}
        </div>

        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {promotions.length === 0 ? (
            <div
              style={{
                height: "100px",
              }}
            >
              <Empty
                description={<span>{t("empty:Nodata")}</span>}
                css={css`
                  height: 100% !important;
                  justify-content: center !important;
                  display: flex !important;
                  flex-direction: column !important;
                `}
              />
            </div>
          ) : (
            promotions.map((promotion: PromotionType, index: number) => (
              <div
                css={ModalDepositStyle.selectCurrency}
                key={promotion.id}
                style={{
                  borderRadius:
                    index === 0 ? "10px 10px 0px 0px" : "0px 0px 10px 10px",
                  borderBottom: "1px solid #F5F5F5",
                }}
                onClick={() => {
                  if (promotionId === promotion.id) {
                    setPromotionId("0");
                    setPromotionName("Choose from promotion");
                    return;
                  }

                  setPromotionId(promotion.id);
                  setPromotionName(promotion.title);
                }}
              >
                <p>{promotion.title}</p>
                {promotionId && promotion.id.includes(promotionId) && (
                  <CheckedIcon />
                )}
              </div>
            ))
          )}
        </div>

        <Button
          css={ModalDepositStyle.button}
          style={{ marginTop: "20px" }}
          onClick={() => setIsOpenModalPromotion(false)}
        >
          {t("button:Apply")}
        </Button>
      </div>
    </Modal>
  );
};
