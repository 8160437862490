import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const InputFileStyle = {
  self: css`
    & .ant-upload {
      width: 100%;
      cursor: pointer;
      &-disabled {
        opacity: 0.8;
      }
    }
  `,

  content: (isDeposit: boolean) => css`
    width: 100%;
    height: ${isDeposit ? "44px" : "52px"};
    border-radius: 8px;
    border: none;
    color: ${isDeposit ? "#66666c" : "white"};
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px 10px;
    &:hover {
      transition: 0.25s ease;
    }
    border: 1px solid rgba(0, 0, 0, 0.1);
  `,
  contentError: css`
    border: 1px solid var(--error-color);
  `,

  placeholder: (isDeposit: boolean) => css`
    color: ${isDeposit ? "#66666c" : "white"};
  `,
  placeholderError: css`
    color: var(--error-color);
  `,

  fileName: css`
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: inherit;

    span {
      color: ${theme.colors.brown[20]};
    }
  `,

  iconUpload: (isDeposit: boolean) => css`
    svg,
    path {
      fill: ${isDeposit ? "#a4a4ab" : "#83696a"};
      vertical-align: middle;
    }
  `,
  iconUploadError: css`
    svg,
    path {
      fill: var(--error-color);
    }
  `,
};
