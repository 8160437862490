/** @jsxImportSource @emotion/react */

import { Button, Modal } from "antd";
import { DepositSubmissionFormTypeMapping } from "api/deposit/type";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import { Dispatch, SetStateAction } from "react";
import { UseFormSetValue } from "react-hook-form";
import { OptionSelect } from "types";
import { OfflineBankingChannelType } from "types/deposit";

import { ModalDepositStyle } from "./index.style";

interface Props {
  isOpenModalDeposit: boolean;
  setIsOpenModalDeposit: Dispatch<SetStateAction<boolean>>;
  setType: Dispatch<SetStateAction<number>>;
  setBank_name: Dispatch<SetStateAction<string>>;
  setPictureBanking: Dispatch<SetStateAction<string>>;
  setBank_account_id: Dispatch<SetStateAction<string>>;
  setTypeName: Dispatch<SetStateAction<string>>;
  type: number;
  offline_banking_channel: OfflineBankingChannelType[];
  setMinDeposit: Dispatch<SetStateAction<number>>;
  setMaxDeposit: Dispatch<SetStateAction<number>>;
  setValue: UseFormSetValue<DepositSubmissionFormTypeMapping>;
}

const listOptionFormDeposit = [
  {
    value: 1,
    label: "Offline Deposit",
  },
  {
    value: 2,
    label: "Online Deposit",
  },
];

export const ModalDepositForm = (props: Props) => {
  const {
    isOpenModalDeposit,
    setIsOpenModalDeposit,
    setType,
    setBank_name,
    setPictureBanking,
    setTypeName,
    setBank_account_id,
    offline_banking_channel,
    type,
    setMinDeposit,
    setMaxDeposit,
    setValue,
  } = props;
  return (
    <Modal
      open={isOpenModalDeposit}
      onCancel={() => setIsOpenModalDeposit(false)}
      centered
      css={ModalDepositStyle.modal}
      footer={null}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Choose form deposit
        </div>

        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {listOptionFormDeposit.map((item: OptionSelect, index: number) => (
            <div
              css={ModalDepositStyle.selectCurrency}
              key={item.value}
              style={{
                borderRadius:
                  index === 0 ? "10px 10px 0px 0px" : "0px 0px 10px 10px",
                borderBottom: "1px solid #F5F5F5",
              }}
              onClick={() => {
                setType(item.value as number);
                setTypeName(item.label);
                setValue("type", item.value as number);

                if (!offline_banking_channel.length) return;

                setBank_name(offline_banking_channel[0].bank_name);
                setBank_account_id(offline_banking_channel[0].bank_account_id);
                setPictureBanking(offline_banking_channel[0].picture);
                setMinDeposit(Number(offline_banking_channel[0].min_deposit));
                setMaxDeposit(Number(offline_banking_channel[0].max_deposit));
              }}
            >
              <p>{item.label}</p>
              {item.value === type && <CheckedIcon />}
            </div>
          ))}

          <Button
            css={ModalDepositStyle.button}
            style={{ marginTop: "20px" }}
            onClick={() => setIsOpenModalDeposit(false)}
          >
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};
