/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Image, Modal } from "antd";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import { Dispatch, SetStateAction } from "react";
import { theme } from "styles/theme";
import { OfflineBankingChannelType } from "types/deposit";

import { ModalDepositStyle } from "./index.style";

interface Props {
  isOpenModalBank: boolean;
  setIsOpenModalBank: Dispatch<SetStateAction<boolean>>;
  offline_banking_channel: OfflineBankingChannelType[];
  setBank_name: Dispatch<SetStateAction<string>>;
  setPictureBanking: Dispatch<SetStateAction<string>>;
  setBank_account_id: Dispatch<SetStateAction<string>>;
  bank_account_id: string;
}

export const ModalBanking = (props: Props) => {
  const {
    isOpenModalBank,
    setIsOpenModalBank,
    setBank_name,
    offline_banking_channel,
    setPictureBanking,
    setBank_account_id,
    bank_account_id,
  } = props;
  return (
    <Modal
      open={isOpenModalBank}
      onCancel={() => setIsOpenModalBank(false)}
      centered
      css={ModalDepositStyle.modal}
      footer={null}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Choose receiving bank
        </div>

        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {offline_banking_channel
            .slice(0, 6)
            .map((offlineBanking: OfflineBankingChannelType, index: number) => (
              <div
                css={ModalDepositStyle.selectCurrency}
                key={offlineBanking.bank_account_id}
                style={{
                  borderRadius: "10px",
                  borderBottom: "1px solid #F5F5F5",
                  marginBottom: "4px",
                  height: "88px",
                }}
                onClick={() => {
                  setBank_name(offlineBanking.bank_name);
                  setBank_account_id(offlineBanking.bank_account_id);
                  setPictureBanking(offlineBanking.picture);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image src={offlineBanking.picture} height={62} width={62} />
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: center;
                      margin-left: 8px;
                      font-size: 16px;
                    `}
                  >
                    <p>{offlineBanking.bank_name}</p>
                    <p
                      css={css`
                        color: ${theme.colors.gray[300]};
                      `}
                    >
                      {offlineBanking.bank_account_name}
                    </p>
                    <p
                      css={css`
                        color: ${theme.colors.gray[300]};
                      `}
                    >
                      {offlineBanking.bank_account_no}
                    </p>
                  </div>
                </div>
                {offlineBanking.bank_account_id === bank_account_id && (
                  <CheckedIcon />
                )}
              </div>
            ))}
        </div>

        <Button
          css={ModalDepositStyle.button}
          style={{ marginTop: "20px" }}
          onClick={() => setIsOpenModalBank(false)}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};
