/* eslint-disable prefer-const */
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { GameApi } from "api/game";
import axios, { CancelTokenSource } from "axios";
import { ResponseCode } from "contants/response";
import { updateNameLoginGame } from "store/game";
import { setOpenModal } from "store/modal";
import { StatusNotification } from "types";
import { SubGameType } from "types/game";

let source: CancelTokenSource;

export const handleLoginGame = async (
  dataLogin: SubGameType,
  username: string,
  dispatch: Dispatch<AnyAction>,
  openNotification: (status: StatusNotification, message: string) => void
) => {
  const promotionStatus = localStorage.getItem("promotionStatus");
  const promotionMessage = localStorage.getItem("promotionMessage");
  console.log(dataLogin);
  if (!username) {
    dispatch(setOpenModal("signin"));
    return;
  }

  if (promotionStatus === "1" && dataLogin.type !== "SL") {
    openNotification("error", String(promotionMessage));
    return;
  } else if (promotionStatus === "0" && dataLogin.type !== "LC") {
    openNotification("error", String(promotionMessage));
    return;
  }

  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  source = axios.CancelToken.source();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
  let openBlank = window.open("", "LoginGame");

  if (!openBlank) return;

  let loaderElement = openBlank.document.createElement("div");
  const style = openBlank.document.createElement("style");

  loaderElement.className = "loader";

  style.textContent = `
  .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:#333333;} 
  .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#298548;border-radius:50%;animation:loading 2s ease infinite} 
  @keyframes loading {
    from { transform: rotate(0turn)}
    to { transform:rotate(1turn)}
  }
  `;

  dispatch(updateNameLoginGame(openBlank.name));
  openBlank.document.body.appendChild(loaderElement);
  openBlank.document.head.appendChild(style);

  try {
    const response = await GameApi.loginGame(username, {
      username,
      game_type: dataLogin.type,
      game_code: dataLogin.game_code,
      provider: dataLogin.provider_code,
      source,
    });

    const dataLoginGame = response.data;

    if (dataLoginGame.status !== ResponseCode.SUCCESS) {
      openNotification("error", dataLoginGame.msg);
      openBlank?.close();
      dispatch(updateNameLoginGame(""));
      return;
    }

    openNotification("success", dataLoginGame.msg);

    (openBlank?.location as { href: string }).href = dataLoginGame.url;
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        "Operation canceled due to new request."
      )
    ) {
      return;
    }
    openNotification(
      "error",
      "Server is under maintenance, please try again later!"
    );
    dispatch(updateNameLoginGame(""));
  }
};
