import { css, Theme } from "@emotion/react";
import { theme } from "styles/theme";

export const UserLayoutStyle = {
  self: css`
    background: ${theme.colors.mainBackground};
    min-height: 100%;
  `,

  contentWrapper: (background: string) => css`
    display: flex;
    gap: 28px;
    padding: 90px 32px 24px;
    /* background-image: url(${background}); */
    /* background-repeat: no-repeat; */
    /* background-size: 100% 250px; */
    background-color: ${theme.colors.mainBackground};
    min-width: 1200px;
    min-height: calc(100dvh - 247px);
  `,

  mainContent: ({ colors }: Theme) => css`
    flex: 1;
  `,
};
