/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import { useAppDispatch } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { setOpenModal } from "store/modal";

import { HeaderUnAuthStyle } from "./style.index";

const HeaderUnAuth = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("header");
  return (
    <div css={HeaderUnAuthStyle.wrapper}>
      <Button
        onClick={() => dispatch(setOpenModal("signin"))}
        css={HeaderUnAuthStyle.buttonLogin}
      >
        {t("Log In")}
      </Button>
      <Button
        onClick={() => dispatch(setOpenModal("signup"))}
        css={HeaderUnAuthStyle.buttonRegister}
      >
        {t("Join Now")}
      </Button>
    </div>
  );
};

export default HeaderUnAuth;
