/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Image, Modal } from "antd";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import { Dispatch, SetStateAction } from "react";
import { ChannelOnlineType, OnlineBankingChannelType } from "types/deposit";

import { ModalDepositStyle } from "./index.style";

interface Props {
  isOpenModalGateway: boolean;
  setIsOpenModalGateWay: Dispatch<SetStateAction<boolean>>;
  online_banking_channel: OnlineBankingChannelType[];
  setGateway: Dispatch<SetStateAction<string>>;
  gateWay: string;
  setPictureGateway: Dispatch<SetStateAction<string>>;
  setChannels: Dispatch<SetStateAction<ChannelOnlineType[]>>;
}

export const ModalGateway = (props: Props) => {
  const {
    isOpenModalGateway,
    online_banking_channel,
    setGateway,
    setIsOpenModalGateWay,
    setPictureGateway,
    gateWay,
    setChannels,
  } = props;
  return (
    <Modal
      open={isOpenModalGateway}
      onCancel={() => setIsOpenModalGateWay(false)}
      centered
      css={ModalDepositStyle.modal}
      footer={null}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Choose receiving bank
        </div>

        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {online_banking_channel.map(
            (onlineBanking: OnlineBankingChannelType, index: number) => (
              <div
                css={ModalDepositStyle.selectCurrency}
                key={onlineBanking.id}
                style={{
                  borderRadius: "10px",
                  borderBottom: "1px solid #F5F5F5",
                  marginBottom: "4px",
                  height: "88px",
                }}
                onClick={() => {
                  setGateway(onlineBanking.gateway);
                  setPictureGateway(onlineBanking.picture);
                  setChannels(onlineBanking.channel);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image src={onlineBanking.picture} height={62} width={62} />
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: center;
                      margin-left: 8px;
                      font-size: 16px;
                    `}
                  >
                    <p>{onlineBanking.gateway}</p>
                  </div>
                </div>
                {onlineBanking.gateway === gateWay && <CheckedIcon />}
              </div>
            )
          )}
        </div>

        <Button
          css={ModalDepositStyle.button}
          style={{ marginTop: "20px" }}
          onClick={() => setIsOpenModalGateWay(false)}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};
