/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Empty, Image, Modal } from "antd";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import { Dispatch, SetStateAction } from "react";
import { ChannelOnlineType } from "types/deposit";

import { ModalDepositStyle } from "./index.style";

interface Props {
  isOpenModalChannel: boolean;
  setIsOpenModalChannel: Dispatch<SetStateAction<boolean>>;
  setCodeChannel: (code: string) => void | Dispatch<SetStateAction<string>>;
  setNameChannel: (name: string) => void | Dispatch<SetStateAction<string>>;
  channelCode: string;

  channels: ChannelOnlineType[];
}

export const ModalChannel = (props: Props) => {
  const {
    isOpenModalChannel,
    setCodeChannel,
    setIsOpenModalChannel,
    setNameChannel,
    channelCode,
    channels,
  } = props;
  return (
    <Modal
      open={isOpenModalChannel}
      onCancel={() => setIsOpenModalChannel(false)}
      centered
      css={ModalDepositStyle.modal}
      footer={null}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Choose Channel
        </div>

        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {channels.length === 0 ? (
            <div
              style={{
                height: "100px",
              }}
            >
              <Empty
                css={css`
                  height: 100% !important;
                  justify-content: center !important;
                  display: flex !important;
                  flex-direction: column !important;
                `}
              />
            </div>
          ) : (
            channels.map((channel: ChannelOnlineType, index: number) => (
              <div
                css={css`
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  background-color: white;
                  width: 100%;
                  cursor: pointer;
                `}
                key={channel.code}
                style={{
                  borderRadius:
                    index === 0 ? "10px 10px 0px 0px" : "0px 0px 10px 10px",
                  borderBottom: "1px solid #F5F5F5",
                }}
                onClick={() => {
                  setCodeChannel(channel.code);
                  setNameChannel(channel.name);
                }}
              >
                <Image
                  src={channel.picture}
                  height={42}
                  width={42}
                  css={css`
                    height: 100%;
                    width: 100%;
                  `}
                />
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1;
                    margin-left: 10px;
                  `}
                >
                  <p>{channel.name}</p>
                  {channelCode && channel.code.includes(channelCode) && (
                    <CheckedIcon />
                  )}
                </div>
              </div>
            ))
          )}
        </div>

        <Button
          css={ModalDepositStyle.button}
          style={{ marginTop: "20px" }}
          onClick={() => setIsOpenModalChannel(false)}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};
