// import BengaliIcon from "assets/Mobile/icons/bengali_flag.png";
import EnglishIcon from "assets/Mobile/icons/english_flag.png";
export interface ItemLanguageType {
  id: string;
  value: string;
  label: string;
  picture: string;
}
export const languageOptions: ItemLanguageType[] = [
  { id: "en", value: "en", label: "English", picture: EnglishIcon },
  // { id: "bn", value: "bn", label: "Bengali", picture: BengaliIcon },
];
