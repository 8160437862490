/** @jsxImportSource @emotion/react */
import { ReactComponent as CheckedSvg } from "assets/Mobile/icons/checked.svg";
import { ModalCustom } from "components/Common/ModalCustom";
import { languageOptions } from "contants/list-language";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { setClientCountryCode } from "store/master";
import { selectOpenModal, setOpenModal } from "store/modal";
import { theme } from "styles/theme";

import { LanguageModalStyles } from "./index.style";

interface Props {
  isOpenModal?: boolean;
  setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
}

function LanguageModal(props: Props) {
  const { isOpenModal, setIsOpenModal: setOpenModalLanguage } = props;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const openModal = useAppSelector(selectOpenModal);

  const handleCloseModal = () => {
    if (setOpenModalLanguage) {
      setOpenModalLanguage(false);
      return;
    }

    dispatch(setOpenModal(""));
  };
  const changeLanguage = (lang: string) => {
    dispatch(setClientCountryCode(lang));
    i18n.changeLanguage(lang);
    setTimeout(() => {
      handleCloseModal();
    }, 500);
  };

  return (
    <ModalCustom
      isOpenModal={isOpenModal || openModal.includes("language")}
      handleCloseModal={handleCloseModal}
      titleModal={t("language:Language")}
      heightBackground={75}
      bodyModal={
        <>
          <div css={LanguageModalStyles.container}>
            {languageOptions.map((itemLanguage, index) => {
              return (
                <div
                  css={LanguageModalStyles.item}
                  onClick={() => changeLanguage(itemLanguage.value)}
                  key={`key-${index}-language`}
                >
                  <img src={itemLanguage.picture} alt="" />
                  <span>{t(`language:${itemLanguage.label}`)}</span>
                  {i18n.language === itemLanguage.value && (
                    <CheckedSvg fill={theme.colors.darkGreen} />
                  )}
                </div>
              );
            })}
          </div>
        </>
      }
    />
  );
}

export default LanguageModal;
