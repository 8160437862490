/** @jsxImportSource @emotion/react */

import { ReactComponent as DropDownIcon } from "assets/Common/icons/chevron-down-solid.svg";
import EnglishIcon from "assets/Mobile/icons/english_flag.png";
import { ItemLanguageType, languageOptions } from "contants/list-language";
import { useAppDispatch } from "hooks/app-hook";
import { i18n } from "i18n";
import { find } from "lodash";
import { setOpenModal } from "store/modal";

import { LanguageSelectStyle } from "./style.index";

const LanguageSelect = () => {
  const dispatch = useAppDispatch();
  const itemLanguage = find(
    languageOptions,
    (item: ItemLanguageType) => i18n.language === item.value
  );

  return (
    <div
      onClick={() => dispatch(setOpenModal("language"))}
      css={LanguageSelectStyle.wrapper}
    >
      <div className="icon">
        <img src={itemLanguage ? itemLanguage.picture : EnglishIcon} alt="" />
      </div>
      <div className="dropdownIcon">
        <DropDownIcon />
      </div>
    </div>
  );
};

export default LanguageSelect;
