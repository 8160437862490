import { PATH } from "contants/path";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { selectIsAuthenticated } from "store/auth";
import { setOpenModal } from "store/modal";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");

  useEffect(() => {
    if (!isAuth && !token && !username) {
      dispatch(setOpenModal("signin"));
      navigate(PATH.Home);
    }
  }, [isAuth]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
