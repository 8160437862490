import SwiperImage_1 from "assets/Desktop/images/homepage/slider-image-1.png";
import SwiperImage_2 from "assets/Desktop/images/homepage/slider-image-2.png";
import SwiperImage_3 from "assets/Desktop/images/homepage/slider-image-3.png";
import SwiperImage_4 from "assets/Desktop/images/homepage/slider-image-4.png";
import SwiperImage_5 from "assets/Desktop/images/homepage/slider-image-5.png";
import { PATH } from "contants/path";
import { GameTypeEnum } from "enums/game";

export interface TypeGameSwiper {
  id: string;
  image: string;
  link: string;
  key?: number;
}

export const LIST_GAMES_SWIPER: TypeGameSwiper[] = [
  {
    image: SwiperImage_1,
    key: 1,
    link: PATH.liveGamePage,
    id: GameTypeEnum.LiveCasino,
  },
  {
    image: SwiperImage_2,
    key: 2,
    link: PATH.sportGamePage,
    id: GameTypeEnum.Sport,
  },
  {
    image: SwiperImage_3,
    key: 3,
    link: PATH.slotGamePage,
    id: GameTypeEnum.Slot,
  },
  {
    image: SwiperImage_4,
    key: 4,
    link: PATH.fishingGamePage,
    id: GameTypeEnum.Fishing,
  },
  {
    image: SwiperImage_5,
    key: 5,
    link: PATH.boardGamePage,
    id: GameTypeEnum.ESport,
  },
];
