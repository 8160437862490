import loadable from "@loadable/component";
import App from "App";
import { PATH } from "contants/path";
import DefaultLayout from "layouts/Mobile/DefaultLayout";
import ReferralAgent from "pages/Common/ReferralAgent";
import { RouteObject } from "react-router-dom";

import ProtectedRoute from "./protected";

const MemberDocumentsPage = loadable(
  () => import("pages/Common/MemberDocuments")
);
const MemberVipPage = loadable(() => import("pages/Common/MemberVip"));
const NotFoundPage = loadable(() => import("pages/Common/NotFound"));
const TermsAndConditions = loadable(
  () => import("pages/Common/TermsAndConditions")
);
const HomePage = loadable(() => import("pages/Mobile/HomePage"));
const BrandPage = loadable(() => import("pages/Common/Brand"));
const PromotionPage = loadable(() => import("pages/Common/PromotionPage"));
const SubGameMobile = loadable(() => import("pages/Mobile/SubGamePage"));
const ProfilePage = loadable(() => import("pages/Mobile/ProfilePage"));
const TransactionHistory = loadable(
  () => import("pages/Mobile/TransactionHistory")
);
const BettingHistory = loadable(() => import("pages/Mobile/BettingHistory"));
const UpdateInfo = loadable(() => import("pages/Mobile/UpdateInfo"));
const Announcement = loadable(() => import("pages/Mobile/Announcement"));
const ChangePassword = loadable(() => import("pages/Mobile/ChangePassword"));
const Referral = loadable(() => import("pages/Mobile/Referral"));
const Withdraw = loadable(() => import("pages/Mobile/Withdraw"));
const Contact = loadable(() => import("pages/Common/Contact"));
const Deposit = loadable(() => import("pages/Mobile/Deposit"));
const MRewardPage = loadable(() => import("pages/Mobile/MRewardPage"));

export const mobileRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: "/",
            element: <HomePage />,
          },
          {
            path: PATH.Promotion,
            element: <PromotionPage />, //done
          },
          // {
          //   path: PATH.PromotionDetail,
          //   element: <PromotionDetailPage />,
          // },
          {
            path: PATH.referral,
            element: <ReferralAgent />, // done
          },
          {
            path: PATH.Brand,
            element: <BrandPage />, //done
          },
          {
            path: PATH.SubGame,
            element: <SubGameMobile />,
          },
          {
            path: PATH.TermsAndConditions,
            element: <TermsAndConditions />, //done
          },
          {
            path: PATH.contactUs,
            element: <Contact />, //done
          },

          {
            element: <ProtectedRoute />,
            children: [
              {
                path: PATH.Deposit,
                element: <Deposit />,
              },
              {
                path: PATH.Withdraw,
                element: <Withdraw />,
              },

              {
                path: PATH.User.profile,
                element: <ProfilePage />, //done
              },
              {
                path: PATH.User.TransactionHistory,
                element: <TransactionHistory />, //done
              },
              {
                path: PATH.User.BettingRecord,
                element: <BettingHistory />, //done
              },
              {
                path: PATH.User.UpdateInfo,
                element: <UpdateInfo />, //done
              },
              {
                path: PATH.User.myVip,
                element: <MemberVipPage />, //done
              },
              {
                path: PATH.User.Referral,
                element: <Referral />, // done
              },
              {
                path: PATH.User.Announcement,
                element: <Announcement />, //done
              },
              {
                path: PATH.User.reward,
                element: <MRewardPage />, //done
              },
              {
                path: PATH.User.memberDocuments,
                element: <MemberDocumentsPage />, //done
              },
              {
                path: PATH.User.changePassword,
                element: <ChangePassword />, //done
              },
              {
                path: PATH.User.Referral,
                element: <Referral />, //done
              },
            ],
          },
          {
            path: "*",
            element: <NotFoundPage />, //done
          },
        ],
      },
    ],
  },
];
