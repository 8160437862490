import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const DefaultLayoutStyle = {
  self: css`
    background-color: #f5f9fd;

    main {
      background-color: ${theme.colors.mainBackground};
      padding-bottom: 80px;
    }
  `,
};
