import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import {
  BettingRecordDetailPayload,
  BettingRecordPayload,
  BettingRecordResponseType,
} from "./type";

export const bettingRecordApi = {
  async wager(username: string, payload: BettingRecordPayload) {
    const response = await axiosRequest.post<BettingRecordResponseType>(
      "",
      payloadHelper("Wager", username, payload)
    );
    return response.data;
  },
  async wagerDetail(username: string, payload: BettingRecordDetailPayload) {
    const response = await axiosRequest.post<BettingRecordResponseType>(
      "",
      payloadHelper("WagerDetail", username, payload)
    );
    return response.data;
  },
};
