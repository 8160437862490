import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const LanguageSelectStyle = {
  wrapper: css`
    padding: 4px 6px;
    background: linear-gradient(180deg, #fafafa 0%, #f4e7e8 100%);
    margin-left: 12px;
    border-radius: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .dropdownIcon {
      width: 14px;
      height: 14px;
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${theme.colors.gray[200]};
      }
    }
  `,
};
