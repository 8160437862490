import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const RecoveryModalStyle = {
  formContainer: () => css`
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-right: 32px;
  `,

  buttonSubmit: () => css`
    height: 44px;
    background: ${theme.colors.gray["10"]};
    border-radius: 8px;
    border: 0px;
    margin-top: 16px;
    color: ${theme.colors.text.white_light};
    cursor: inherit;
  `,

  buttonActive: () => css`
    background-color: ${theme.colors.mainBackground};
    color: ${theme.colors.text.white};
    cursor: pointer;
  `,

  dataPassword: () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    span {
      text-align: center;
      color: red;
      font-weight: bold;
    }
  `,
  login: () => css`
    margin-top: 16px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: ${theme.colors.gray["300"]};
    margin-bottom: 6px;

    span {
      color: ${theme.colors.darkGreen};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `,

  optCode: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;

    margin-bottom: 8px;
    input {
      width: 100%;
      height: 44px;
      border: 0;
      padding: 0 8px;
      outline: none !important;
    }

    button {
      border: 0;
      background-color: ${theme.colors.darkGreen};
      height: 36px;
      padding: 0 8px;
      margin-right: 4px;

      &:disabled {
        background-color: ${theme.colors.gray[200]};
      }

      span {
        line-height: unset !important;
        color: ${theme.colors.white};
      }
    }
  `,
};
