import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import {
  GetBannerResponseType,
  GetClaimPromotion,
  GetPromotionListResponse,
  PayloadClaimType,
} from "./type";

export const retrieveApi = {
  async getPromotionList() {
    const { data } = await axiosRequest.post<GetPromotionListResponse>(
      "/",
      payloadHelper("PromotionList", null, null)
    );
    return data;
  },
  async getBanner() {
    const { data } = await axiosRequest.post<GetBannerResponseType>(
      "/",
      payloadHelper("Banner", null, null)
    );
    return data;
  },
  async getClaimPromotion(payloadClaim: PayloadClaimType) {
    const { data } = await axiosRequest.post<GetClaimPromotion>(
      "/",
      payloadHelper(
        "ClaimPromotionDailyRebate",
        payloadClaim.username,
        payloadClaim
      )
    );
    return data;
  },
};
