import { createAsyncThunk } from "@reduxjs/toolkit";
import { bettingRecordApi } from "api/bettingrecord";
import {
  BettingRecordDetailPayload,
  BettingRecordPayload,
} from "api/bettingrecord/type";

export const getWager = createAsyncThunk(
  "bettingRecord/Wager",
  async (payload: BettingRecordPayload, { rejectWithValue }) => {
    try {
      const dataBettingRecord = await bettingRecordApi.wager(
        payload.username,
        payload
      );
      return dataBettingRecord;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWagerDetail = createAsyncThunk(
  "bettingRecord/WagerDetail",
  async (payload: BettingRecordDetailPayload, { rejectWithValue }) => {
    try {
      const dataBettingRecord = await bettingRecordApi.wagerDetail(
        payload.username,
        payload
      );
      return dataBettingRecord;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
