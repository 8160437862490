import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const InputCustomStyle = {
  self: css`
    &.ant-input,
    .ant-input {
      width: 100%;
      height: 44px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
      font-size: 14px;
      background-color: ${theme.colors.white} !important;
      border-radius: 8px;
      color: #000000;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      ${media["2sm"]} {
        input {
          box-shadow: none;
        }
      }

      &:-webkit-autofill {
        -webkit-background-clip: text;
      }

      &:focus,
      &:hover {
        border-color: rgba(0, 0, 0, 0.1);
      }
      &-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &-status-error {
        border-color: var(--error-color) !important;
        &::placeholder {
          color: var(--error-color);
        }
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
};
