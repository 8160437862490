import { css, Theme } from "@emotion/react";

export const SigninModalStyle = {
  formContainer: () => css`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-right: 32px;
  `,

  forgetPass: ({ colors }: Theme) => css`
    margin-top: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: ${colors.darkGreen};
    cursor: pointer;
  `,

  buttonSubmit: ({ colors }: Theme) => css`
    height: 44px;
    background: ${colors.gray[150]} !important;
    border-radius: 8px;
    border: 0px;
    margin-top: 24px;
    color: ${colors.white} !important;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    cursor: inherit;
  `,

  buttonActive: ({ colors }: Theme) => css`
    background-color: ${colors.mainBackground} !important;
    color: ${colors.white} !important;
    cursor: pointer;
  `,

  register: ({ colors }: Theme) => css`
    margin-top: 16px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.gray["300"]};
    margin-bottom: 6px;

    span {
      color: ${colors.darkGreen};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
};
