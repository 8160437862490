/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import { ReactComponent as ArrowTopIcon } from "assets/Common/icons/arrow-top.svg";

import { BackToTopStyle } from "./index.style";

const BackToTop = () => {
  const handleBackToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div css={BackToTopStyle.self}>
      <Button
        size="small"
        icon={<ArrowTopIcon />}
        css={BackToTopStyle.btnBackToTop}
        onClick={handleBackToTop}
      />
      <p css={BackToTopStyle.backTitle}>Back</p>
    </div>
  );
};

export default BackToTop;
