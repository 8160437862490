import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const BannerStyle = {
  index: css`
    .slick-dots {
      bottom: 25px !important;
    }

    .slick-slide {
      pointer-events: none;
    }

    .slick-active {
      pointer-events: unset;
    }
  `,
  banner: css`
    display: block;
    width: 100%;
    height: 800px;
    position: relative;
    object-fit: contain;
    overflow: hidden;
    ${media.xxl} {
      height: 500px;
    }
    ${media.xl} {
      height: 400px;
    }
    img {
      cursor: pointer;
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      aspect-ratio: 2.4/1;
    }
  `,
};
