import styled from "@emotion/styled";

export const ButtonTelegramStyle = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;

  overflow: hidden;

  .img {
    display: block;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
