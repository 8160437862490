import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
export const DropdownGameProviderStyle = {
  container: (visible: boolean) => css`
    background: linear-gradient(
      180deg,
      rgba(242, 244, 247, 0.97) 0%,
      rgba(221, 230, 238, 0.97) 100%
    );

    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    opacity: ${visible ? 1 : 0};
    pointer-events: ${visible ? "visible" : "none"};
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
    overflow: hidden;

    &.drop-game-SB {
      height: ${visible ? "calc(75px + 260px)" : "0px"};
      ${media.xl} {
        height: ${visible
          ? "calc(75px + 12px + (((100vw - 24px) / 4) * 614 / 810))"
          : "0px"};
      }
      .boxGame {
        grid-template-columns: repeat(4, 1fr) !important;
        ${media.xl} {
          padding: 0 12px;
        }
        .itemGame {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .item-image {
          width: 294px;
          height: 216px;

          ${media.xl} {
            width: calc((100vw - 24px) / 4);
            height: calc(100%);
          }
        }
      }
    }

    &.drop-game-LC {
      height: ${visible ? "calc(75px + 12px + (204px * 2) + 54px)" : "0px"};

      ${media.xl} {
        height: ${visible
          ? "calc(75px + 32px + (100vw / 5) * 614 / 588)"
          : "0px"};
      }
      .boxGame {
        grid-template-columns: repeat(5, 1fr) !important;
        .itemGame {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .item-image {
          width: 270px;
          height: 215px;

          & img {
            width: 100%;
            height: 100%;
          }

          ${media.xl} {
            width: calc(100vw / 5);
            height: calc(100%);
          }
        }
      }
    }
    &.drop-game-SL {
      height: ${visible ? "calc(75px + 12px + (204px * 3) + 54px)" : "0px"};
      ${media.xl} {
        height: ${visible
          ? "calc(75px + 24px + ((100vw / 4) * 614 / 810) * 2 + 12px)"
          : "0px"};
      }
      .boxGame {
        grid-template-columns: repeat(5, 1fr) !important;
        .itemGame {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .item-image {
          width: 270px;
          height: 204px;
          ${media.xl} {
            width: calc(100vw / 4);
            height: calc(100%);
          }
        }
      }
    }
    &.drop-game-FH {
      height: ${visible ? "calc(75px + 32px + 216px)" : "0px"};
      max-height: calc(75px + 32px + 216px);
      ${media.xl} {
        height: ${visible
          ? "calc(75px + 32px + ((100vw / 3) * 648 / 888))"
          : "0px"};
      }
      .boxGame {
        grid-template-columns: repeat(4, 1fr) !important;
        gap: 0 96px;
        ${media.xl} {
          gap: 0;
        }
        .itemGame {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .item-image {
          width: 296px;
          height: 216px;
          max-width: 296px;
          max-height: 216px;

          ${media.xl} {
            width: calc(100vw / 3);
            height: calc(100%);
          }
        }
      }
    }
    &.drop-game-CF {
      height: ${visible ? "calc(75px + 24px + 216px)" : "0px"};

      ${media.xl} {
        ${media.xl} {
          height: ${visible
            ? "calc(75px + 32px + ((100vw / 3) * 648 / 888))"
            : "0px"};
        }
      }
      .boxGame {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 0 96px;
        ${media.xl} {
          gap: 0 32px;
        }
        .itemGame {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .item-image {
          width: 296px;
          height: 216px;
          max-width: 296px;
          max-height: 216px;

          ${media.xl} {
            width: calc(100vw / 3);
            height: calc(100%);
          }
        }
      }
    }
  `,
  content: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(75px + 16px);
  `,
  boxGame: css`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: row;
    gap: 12px 0px !important;
    margin: 0 auto;
  `,
  itemGame: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    max-width: 300px;

    .item-image {
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  `,
};
