/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";
import { theme } from "styles/theme";

import { GaugeItemStyle } from "./style.index";
import { TypeGaugeItem } from "./type";

const GaugeItem = (props: TypeGaugeItem) => {
  const { content, title, score, describe, percent } = props;

  const { t } = useTranslation("home");

  return (
    <div css={GaugeItemStyle.index}>
      <div css={GaugeItemStyle.header}>
        <img
          src="https://www.x33my.com/static/media/gauge.d0718c76.webp"
          alt=""
        />
        <svg viewBox="7 5 88 88" className="gauge">
          <path
            className="dial"
            fill="none"
            stroke="#eee"
            strokeWidth="5"
            d="M 21.716 78.284 A 40 40 0 1 1 78.284 78.284"
          ></path>
          <text
            x="50"
            y="25"
            fill={theme.colors.white}
            className="value-text"
            fontSize="5px"
            fontFamily="sans-serif"
            fontWeight="normal"
            textAnchor="middle"
            alignmentBaseline="middle"
            dominantBaseline="central"
          >
            {t(title)}
          </text>
          <text
            x="50"
            y="50"
            fill={theme.colors.white}
            className="value-text"
            fontSize="30px"
            fontFamily="sans-serif"
            fontWeight="normal"
            textAnchor="middle"
            alignmentBaseline="middle"
            dominantBaseline="central"
          >
            {score}
          </text>
          <text
            x="50"
            y="75"
            fill={theme.colors.white}
            className="value-text"
            fontSize="8px"
            fontFamily="sans-serif"
            fontWeight="normal"
            textAnchor="middle"
            alignmentBaseline="middle"
            dominantBaseline="central"
          >
            {t(describe)}
          </text>
          <path
            className="value"
            fill="none"
            stroke={theme.colors.darkGreen}
            strokeWidth="5.5"
            d={percent}
          ></path>
        </svg>
      </div>
      <div css={GaugeItemStyle.bottom}>
        <span>{t(content)}</span>
      </div>
    </div>
  );
};

export default GaugeItem;
