/** @jsxImportSource @emotion/react */

import { Button, Modal } from "antd";
import { ReactComponent as CheckedIcon } from "assets/temporary/icons/checked.svg";
import { Dispatch, SetStateAction } from "react";
import { CurrencyType } from "types/deposit";

import { ModalDepositStyle } from "./index.style";

interface Props {
  isOpenModalCurrencies: boolean;
  setIsOpenModalCurrencies: Dispatch<SetStateAction<boolean>>;
  currencies: CurrencyType[];
  setCurrencyName: Dispatch<SetStateAction<string>>;
  setCurrencyId: Dispatch<SetStateAction<string>>;
  currencyId: string;
}

export const ModalCurrency = (props: Props) => {
  const {
    currencies,
    currencyId,
    isOpenModalCurrencies,
    setCurrencyId,
    setCurrencyName,
    setIsOpenModalCurrencies,
  } = props;
  return (
    <Modal
      open={isOpenModalCurrencies}
      onCancel={() => setIsOpenModalCurrencies(false)}
      centered
      css={ModalDepositStyle.modal}
      footer={null}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Choose Currency
        </div>

        <div
          style={{
            marginBottom: "20px",
          }}
        >
          {currencies.map((currency: CurrencyType, index: number) => (
            <div
              css={ModalDepositStyle.selectCurrency}
              key={currency.currency_code}
              style={{
                borderRadius:
                  index === 0 ? "10px 10px 0px 0px" : "0px 0px 10px 10px",
                borderBottom: "1px solid #F5F5F5",
              }}
              onClick={() => {
                setCurrencyId(currency.currency_id);
                setCurrencyName(currency.currency_code);
              }}
            >
              <p>{currency.currency_name}</p>
              {currency.currency_id.includes(currencyId) && <CheckedIcon />}
            </div>
          ))}

          <Button
            css={ModalDepositStyle.button}
            style={{ marginTop: "20px" }}
            onClick={() => setIsOpenModalCurrencies(false)}
          >
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};
