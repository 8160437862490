import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import {
  DepositStateType,
  OfflineBankingChannelType,
  OnlineBankingChannelType,
} from "types/deposit";

import { extraReducers } from "./deposit.extraReducers";

export const initialState: DepositStateType = {
  isLoading: false,
  dataDeposit: {
    bank: [],
    player_bank: [],
    currencies: [],
    offline_banking_channel: [],
    online_banking_channel: [],
    promotion: [],
    setting: {
      min_deposit: "",
      max_deposit: "",
    },
  },
  offlineBankingDetail: {
    bank_account_id: "",
    bank_account_name: "",
    bank_account_no: "",
    bank_name: "",
    bank_id: "",
    picture: "",
    max_deposit: "",
    min_deposit: "",
  },
  onlineBankingDetail: {
    id: "",
    gateway: "",
    picture: "",
    min_deposit: "",
    max_deposit: "",
    channel: [],
  },
};

const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    setOfflineBankingDetail: (
      state: DepositStateType,
      action: PayloadAction<OfflineBankingChannelType>
    ) => {
      state.offlineBankingDetail = { ...action.payload };
    },
    setOnlineBankingDetail: (
      state: DepositStateType,
      action: PayloadAction<OnlineBankingChannelType>
    ) => {
      state.onlineBankingDetail = { ...action.payload };
    },
    resetBankingDetail: (state: DepositStateType) => {
      return {
        ...state,
        offlineBankingDetail: initialState.offlineBankingDetail,
        onlineBankingDetail: initialState.onlineBankingDetail,
      };
    },
    resetDeposit: () => initialState,
  },
  extraReducers,
});

export const selectDataDeposit = (state: ApplicationRootState) =>
  state.deposit.dataDeposit;

export const selectIsLoadingDeposit = (state: ApplicationRootState) =>
  state.deposit.isLoading;

export const selectOfflineBankingDetail = (state: ApplicationRootState) =>
  state.deposit.offlineBankingDetail;

export const selectOnlineBankingDetail = (state: ApplicationRootState) =>
  state.deposit.onlineBankingDetail;

export const {
  resetDeposit,
  setOfflineBankingDetail,
  setOnlineBankingDetail,
  resetBankingDetail,
} = depositSlice.actions;

export default depositSlice.reducer;
