import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { i18nLngLocal } from "contants/common";
import { ApplicationRootState } from "types";
import { masterStateType } from "types/master";

export const initialState: masterStateType = {
  clientCountryCode: "",
};

const master = createSlice({
  name: "master",
  initialState,
  reducers: {
    setClientCountryCode(
      state: masterStateType,
      action: PayloadAction<string>
    ) {
      localStorage.setItem(i18nLngLocal, action.payload);
      state.clientCountryCode = action.payload;
    },
  },
});

export const selectClientCountryCode = (state: ApplicationRootState) =>
  state.master.clientCountryCode;

export const { setClientCountryCode } = master.actions;

export default master.reducer;
