import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const BottomTabsStyle = {
  container: css`
    height: 3rem;
    background-color: ${theme.colors.black};
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px -1px 2px 0px #59595926;
  `,
  lisTabs: css`
    display: flex;
    align-items: center;
  `,
  itemTab: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    &:nth-of-type(3) {
      padding: 0 0 8px 0;
    }
  `,
  itemLink: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;

    /* &.item-tab-home {
      span {
        display: none;
      }
    } */
    &.item-tab-sponsor {
      position: relative;
      cursor: no-drop;
      .badge {
        background-color: #cc6367;
        position: absolute;
        width: max-content;
        font-size: 7px;
        color: ${theme.colors.white};
        top: -4px;
        left: 50%;
        padding: 2px 4px;
        border-radius: 7px;
        text-align: center;
      }

      img {
        width: 1.5rem;
        display: block;
      }
    }
  `,
  itemIcon: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.icon-tab-home {
      object-fit: contain;
      position: absolute;
      width: 70px;
      height: 70px;

      top: -24px;
      background-color: ${theme.colors.black};
      border-radius: 50px;

      box-shadow: 0 -3px 4.5px -4px rgba(0, 0, 0, 0.75);
    }

    .icon-deposit {
      width: 45px;
      height: 45px;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      display: block;
    }
  `,
  itemLabel: (color?: string) => css`
    color: ${color || theme.colors.gray[100]};
    font-size: 0.75rem;
    text-decoration: none;
    font-weight: 500;
  `,
};
