/** @jsxImportSource @emotion/react */

import { SubGameResponse } from "api/game/type";
import SwiperBg from "assets/Desktop/images/homepage/swiper-bg.png";
import { GameTypeEnum } from "enums/game";
import { useAppDispatch } from "hooks/app-hook";
import { useNavigate } from "react-router-dom";
import { updateGameProviderCode } from "store/game";
import { getGameList } from "store/game/game.thunk";

import { TypeGameSwiper } from "./list-game";
import { GamesSwiperStyle } from "./style.index";

const GameSwiper = (props: TypeGameSwiper) => {
  const { image, id, link } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getGameLobby = async (
    idGame: string,
    path: string,
    providerCode?: string
  ) => {
    const response = await dispatch(getGameList(idGame));
    const { data } = response.payload as SubGameResponse;

    if (data.length > 0) {
      dispatch(
        updateGameProviderCode(
          providerCode ? providerCode : data[0].provider_code
        )
      );
      navigate(
        `${path}/${providerCode ? providerCode : data[0].provider_code}`
      );
    }
  };

  const handleRedirectSubGame = (
    idGame: string,
    path: string,
    providerCode?: string
  ) => {
    if (
      idGame.includes(GameTypeEnum.Slot) ||
      idGame.includes(GameTypeEnum.Fishing)
      // idGame.includes(GameTypeEnum.ESport)
    ) {
      return getGameLobby(idGame, path, providerCode);
    }

    navigate(path);
  };

  return (
    <div
      css={GamesSwiperStyle.index}
      onClick={() => handleRedirectSubGame(id, link)}
    >
      <div css={GamesSwiperStyle.shadow}>
        <img src={SwiperBg} alt="" />
      </div>
      <div css={GamesSwiperStyle.img}>
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default GameSwiper;
