/** @jsxImportSource @emotion/react */

import { message } from "antd";
import { ContactApi } from "api/contact";
import IconTelegram from "assets/Common/images/image-telegram.png";
import Loading from "components/Common/Loading";
import { ResponseCode } from "contants/response";
import { useEffect, useState } from "react";
import { ContactType } from "types/contact";

import { ButtonTelegramStyle } from "./style.index";

const ButtonTelegram = () => {
  const [data, setData] = useState<ContactType>();
  const [isLoading, setIsLoading] = useState(false);

  const getContact = async () => {
    const res = await ContactApi.getContact();

    setIsLoading(true);

    if (res.status === ResponseCode.SUCCESS) {
      const itemTelegram = res.data.find(
        (item) => item.Platform === "Telegram"
      );
      setData(itemTelegram);
    } else {
      message.error(res.msg);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getContact();
  }, []);

  const handleLinkClick = () => {
    window.open(`https://${data?.URL}`, "_blank");
  };

  return (
    <ButtonTelegramStyle className="btn__contact-telegram">
      {isLoading ? (
        <Loading fontSize={30} />
      ) : (
        <div className="img" onClick={handleLinkClick}>
          <img src={IconTelegram} alt="" />
        </div>
      )}
    </ButtonTelegramStyle>
  );
};

export default ButtonTelegram;
