import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const LayoutStyle = {
  root: css``,
  main: () => css`
    height: 100vh;
    padding-bottom: 3rem !important;
    max-width: 1024px;
    margin: auto;
    overflow-y: auto;
    background-color: ${theme.colors.mainBackground};

    ::-webkit-scrollbar {
      display: none;
    }
  `,
};
