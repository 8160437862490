// Live

import * as ImageBoardGame from "assets/Desktop/images/game/board-game";
import * as ImageFishing from "assets/Desktop/images/game/fishing";
import ListSymbolLive from "assets/Desktop/images/game/list_symbol-live.png";
import ListSymbolSport from "assets/Desktop/images/game/list_symbol-sport.png";
import * as ImageLiveCasino from "assets/Desktop/images/game/live-casino";
import * as ImageSlots from "assets/Desktop/images/game/slots";
import * as ImageLiveSport from "assets/Desktop/images/game/sports";
import { GameTypeEnum } from "enums/game";

import { PATH } from "./path";

export interface SymbolsType {
  icon: string;
  iconSelected: string;
  providerCode: string;
}

export interface DataDetailCategoryType {
  providerCode: string;
  titleImage: string;
  description: string;
  image: string;
  providerName: string;
  providerImage?: string;
  listSymbolImg?: string;
}

export interface ListDataType {
  id: string;
  name: string;
  symbols: SymbolsType[];
  data: DataDetailCategoryType[];
  path: string;
}

export const LIST_DATA: ListDataType[] = [
  {
    id: GameTypeEnum.LiveCasino,
    name: "Live",
    path: PATH.liveGamePage,
    symbols: [
      // {
      //   icon: ImageLiveCasino.IconEVO,
      //   iconSelected: ImageLiveCasino.IconEVOSelected,
      //   providerCode: "EVO",
      // },
      // {
      //   icon: ImageLiveCasino.IconEZ,
      //   iconSelected: ImageLiveCasino.IconEZSelect,
      //   providerCode: "EZ",
      // },
      {
        icon: ImageLiveCasino.IconSX,
        iconSelected: ImageLiveCasino.IconSXSelected,
        providerCode: "SX",
      },
      {
        icon: ImageLiveCasino.IconDG,
        iconSelected: ImageLiveCasino.IconDGSelected,
        providerCode: "DG",
      },
      {
        icon: ImageLiveCasino.IconPP,
        iconSelected: ImageLiveCasino.IconPPSelected,
        providerCode: "PP",
      },
      {
        icon: ImageLiveCasino.WE,
        iconSelected: ImageLiveCasino.WEActive,
        providerCode: "WE",
      },
      {
        icon: ImageLiveCasino.Bbin,
        iconSelected: ImageLiveCasino.BbinActive,
        providerCode: "BBIN",
      },
      {
        icon: ImageLiveCasino.IconBG,
        iconSelected: ImageLiveCasino.IconBGSelected,
        providerCode: "BG",
      },
    ],
    data: [
      // {
      //   listSymbolImg: ListSymbolLive,
      //   providerCode: "EVO",
      //   titleImage: ImageLiveCasino.titleEVO,
      //   description: "categoryGame:contentEvolution",
      //   image: ImageLiveCasino.imageEVO,
      //   providerName: "categoryGame:Evolution",
      //   providerImage: ImageLiveCasino.evolutionGaming,
      // },
      // {
      //   listSymbolImg: ListSymbolLive,
      //   providerCode: "EZ",
      //   titleImage: ImageLiveCasino.titleEZ,
      //   description: "categoryGame:contentEzugi",
      //   image: ImageLiveCasino.imageEZ,
      //   providerName: "categoryGame:Big",
      //   providerImage: ImageLiveCasino.Ezugi,
      // },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "SX",
        titleImage: ImageLiveCasino.titleSX,
        description: "categoryGame:contentSexy",
        image: ImageLiveCasino.imageSX,
        providerName: "categoryGame:Sexy",
        providerImage: ImageLiveCasino.SexyBaccarat,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "DG",
        titleImage: ImageLiveCasino.titleDG,
        description: "categoryGame:contentDream",
        image: ImageLiveCasino.imageDG,
        providerName: "categoryGame:Dream",
        providerImage: ImageLiveCasino.DreamGaming,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "PP",
        titleImage: ImageLiveCasino.titlePP,
        description: "categoryGame:contentPlaytech",
        image: ImageLiveCasino.imagePP,
        providerName: "categoryGame:Playtech",
        providerImage: ImageLiveCasino.PragmaticPlay,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "WE",
        titleImage: ImageLiveCasino.titleWE,
        description: "categoryGame:contentWE",
        image: ImageLiveCasino.bannerWE,
        providerName: "categoryGame:WE",
        providerImage: ImageLiveCasino.IconWEHeader,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "BBIN",
        titleImage: ImageLiveCasino.titleBB,
        description: "categoryGame:contentBBin",
        image: ImageLiveCasino.bannerBB,
        providerName: "categoryGame:Bbin",
        providerImage: ImageLiveCasino.IconBbinHeader,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "BG",
        titleImage: ImageLiveCasino.titleBG,
        description: "categoryGame:contentBG",
        image: ImageLiveCasino.imageSA,
        providerName: "categoryGame:BG",
        providerImage: ImageLiveCasino.BG,
      },
    ],
  },
  {
    id: GameTypeEnum.Sport,
    path: PATH.sportGamePage,
    name: "Sports",
    symbols: [
      {
        icon: ImageLiveSport.IconSBO,
        iconSelected: ImageLiveSport.IconSelectedSBO,
        providerCode: "SBO",
      },
      {
        icon: ImageLiveSport.IconCMD,
        iconSelected: ImageLiveSport.IconSelectedCMD,
        providerCode: "CMD",
      },
      {
        icon: ImageLiveSport.iconIBC,
        iconSelected: ImageLiveSport.IconSelectedIBC,
        providerCode: "IBC",
      },
      {
        icon: ImageLiveSport.IconPINC,
        iconSelected: ImageLiveSport.IconSelectedPINC,
        providerCode: "PINC",
      },
    ],
    data: [
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "SBO",
        titleImage: ImageLiveSport.titleSBO,
        description: "categoryGame:contentSBO",
        image: ImageLiveSport.imageSBO,
        providerImage: ImageLiveSport.SBO,
        providerName: "categoryGame:SBO",
      },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "CMD",
        titleImage: ImageLiveSport.titleCMD,
        description: "categoryGame:contentCMD",
        image: ImageLiveSport.bgCMD,
        providerImage: ImageLiveSport.CMDSport,
        providerName: "categoryGame:CMD",
      },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "IBC",
        titleImage: ImageLiveSport.titleIBC,
        description: "categoryGame:contentM8",
        image: ImageLiveSport.imageMS,
        providerImage: ImageLiveSport.IBCSport,
        providerName: "categoryGame:M8",
      },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "PINC",
        titleImage: ImageLiveSport.titlePINC,
        description: "categoryGame:contentPINC",
        image: ImageLiveSport.imagePINC,
        providerImage: ImageLiveSport.PINCSport,
        providerName: "categoryGame:PINC",
      },
    ],
  },
  {
    id: GameTypeEnum.Slot,
    name: "Slots",
    path: PATH.slotGamePage,
    symbols: [],
    data: [
      {
        providerCode: "PP",
        titleImage: ImageLiveSport.titleSaba,
        description: "",
        image: ImageSlots.SlotPragmaticPlay,
        providerImage: ImageSlots.SlotPragmaticPlay,
        providerName: "Pragmatic Play",
      },
      {
        providerCode: "JILI",
        titleImage: ImageLiveSport.BtiSport,
        description: "",
        image: ImageSlots.SlotJili,
        providerImage: ImageSlots.SlotJili,
        providerName: "JILI",
      },
      {
        providerCode: "KA",
        titleImage: ImageLiveSport.titleMS,
        description: "",
        image: ImageSlots.SlotKA,
        providerImage: ImageSlots.SlotKA,
        providerName: "KA Gaming",
      },
      {
        providerCode: "HB",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotHabanero,
        description: "",
        image: ImageSlots.SlotHabanero,
        providerName: "Habanero",
      },
      {
        providerCode: "SG",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotSpadeGaming,
        description: "",
        image: ImageSlots.SlotSpadeGaming,
        providerName: "Spade Gaming",
      },
      {
        providerCode: "ACEW",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotAceWin,
        description: "",
        image: ImageSlots.SlotAceWin,
        providerName: "Ace Win",
      },
      {
        providerCode: "FC",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotFaChai,
        description: "",
        image: ImageSlots.SlotFaChai,
        providerName: "FaChai Gaming",
      },
      {
        providerCode: "CQ9",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotCQ9,
        description: "",
        image: ImageSlots.SlotCQ9,
        providerName: "Bbin",
      },
      {
        providerCode: "IBEX",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotIbex,
        description: "",
        image: ImageSlots.SlotIbex,
        providerName: "IBEX",
      },
      {
        providerCode: "JDB",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotJDB,
        description: "",
        image: ImageSlots.SlotJDB,
        providerName: "JDB",
      },
      {
        providerCode: "MEGA",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotMega88,
        description: "",
        image: ImageSlots.SlotMega88,
        providerName: "MEGA",
      },
      {
        providerCode: "DGG",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotDGG,
        description: "",
        image: ImageSlots.SlotDGG,
        providerName: "DGG",
      },
      {
        providerCode: "NXSP",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotNXSP,
        description: "",
        image: ImageSlots.SlotNXSP,
        providerName: "NXSP",
      },
    ],
  },
  {
    id: GameTypeEnum.Fishing,
    name: "Fishing",
    path: PATH.fishingGamePage,
    symbols: [],
    data: [
      {
        providerCode: "ACEW",
        titleImage: ImageLiveSport.titleMS,
        description: "",
        image: ImageFishing.ACEMFishing,
        providerImage: ImageFishing.ACEMFishing,
        providerName: "Ace Win",
      },
      {
        providerCode: "JDB",
        titleImage: ImageLiveSport.titleSaba,
        description: "",
        image: ImageFishing.SpadeGamingFishing,
        providerImage: ImageFishing.SpadeGamingFishing,
        providerName: "JDB",
      },
      {
        providerCode: "JILI",
        titleImage: ImageLiveSport.BtiSport,
        description: "",
        image: ImageFishing.JILIFishing,
        providerImage: ImageFishing.JILIFishing,
        providerName: "JILI",
      },
    ],
  },
  {
    id: GameTypeEnum.ESport,
    name: "E-Sport",
    path: PATH.boardGamePage,
    symbols: [
      {
        icon: ImageBoardGame.ImageEsportDefine,
        iconSelected: ImageBoardGame.ImageEsportActive,
        providerCode: "LH",
      },
    ],
    data: [
      // {
      //   providerCode: "ACEW",
      //   titleImage: ImageBoardGame.ImageBoardGameACEM,
      //   description: "",
      //   image: ImageBoardGame.ImageBoardGameACEM,
      //   providerImage: ImageBoardGame.ImageBoardGameACEM,
      //   providerName: "Ace Win",
      // },
      // {
      //   providerCode: "JILI",
      //   titleImage: ImageBoardGame.ImageBoardGameJILI,
      //   description: "",
      //   image: ImageBoardGame.ImageBoardGameJILI,
      //   providerImage: ImageBoardGame.ImageBoardGameJILI,
      //   providerName: "JILI",
      // },
      // {
      //   providerCode: "KA",
      //   titleImage: ImageBoardGame.ImageBoardGameKA,
      //   description: "",
      //   image: ImageBoardGame.ImageBoardGameKA,
      //   providerImage: ImageBoardGame.ImageBoardGameKA,
      //   providerName: "KA",
      // },
      // {
      //   providerCode: "KM",
      //   titleImage: ImageBoardGame.ImageBoardGameKM,
      //   description: "",
      //   image: ImageBoardGame.ImageBoardGameKM,
      //   providerImage: ImageBoardGame.ImageBoardGameKM,
      //   providerName: "KM",
      // },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "LH",
        titleImage: ImageBoardGame.ImageEsportTitle,
        description: "categoryGame:contentLH",
        image: ImageBoardGame.ImageEsportBanner,
        providerImage: ImageBoardGame.ImageEsport,
        providerName: "categoryGame:LH",
      },
    ],
  },
];
