import axios from "axios";
import { SysLangLocal } from "contants/common";
import { sysLang } from "contants/syslang";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientCountryCode, setClientCountryCode } from "store/master";

import { useAppDispatch } from "./app-hook";

const LangsKey: {
  [key: string]: string;
} = {
  EN: "en",
  BN: "bn",
};

const languageDefault = localStorage.getItem("i18nLng");

export const useIP = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const dataCountryCode = useSelector(selectClientCountryCode);
  const handleLanguage = useCallback(async () => {
    if (languageDefault) {
      dispatch(setClientCountryCode(languageDefault || LangsKey.EN));
      i18n.changeLanguage(languageDefault || LangsKey.EN);
      return;
    }

    const { data } = await axios.get("https://geolocation-db.com/json/");

    const language = LangsKey[data.country_code as string];

    if (!language) {
      dispatch(setClientCountryCode(LangsKey.EN));
      i18n.changeLanguage(LangsKey.EN);
      return;
    }
    dispatch(setClientCountryCode(language));
    i18n.changeLanguage(language);
  }, [i18n]);

  // const sysLangCurrent = sysLang.[dataCountryCode];

  const sysLangCurrent = sysLang[dataCountryCode] || sysLang["en"];

  localStorage.setItem(SysLangLocal, sysLangCurrent + "");

  useEffect(() => {
    handleLanguage();
  }, [handleLanguage]);
};
