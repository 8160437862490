import { notification } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { selectOpenModal, setOpenModal } from "store/modal";
import { StatusNotification } from "types";

import Bank from "./Bank";
import LanguageModal from "./LanguagePage";
import { ModalDeposit } from "./ModalDeposit";
import { ModalWithdraw } from "./ModalWithdraw";
import { RecoveryModal } from "./RecoveryModal";
import { SigninModal } from "./SigninModal";
import { SignupModal } from "./SignupModal";
export const Modal = () => {
  const [params] = useSearchParams();
  const isCode = params.get("code");

  const dispatch = useAppDispatch();
  const openModal = useAppSelector(selectOpenModal);

  const [api, contextHolder] = notification.useNotification();

  // const isEmptyDataBalance = _isEqual(balance, initialState.balance);
  const isDepositModal = openModal.includes("deposit");
  const isWithdrawModal = openModal.includes("withdraw");

  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };

  useEffect(() => {
    if (isCode) {
      dispatch(setOpenModal("signup"));
    }
  }, []);

  return (
    <>
      {contextHolder}
      {openModal.includes("signin") && <SigninModal />}
      {openModal.includes("signup") && <SignupModal isCode={isCode} />}
      {openModal.includes("recoveryPassword") && <RecoveryModal />}
      {openModal.includes("bank") && <Bank />}
      {openModal.includes("language") && <LanguageModal />}
      {isWithdrawModal && <ModalWithdraw openNotification={openNotification} />}
      {isDepositModal && <ModalDeposit openNotification={openNotification} />}
    </>
  );
};
