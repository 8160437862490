import "../i18n";

import { ReactComponent as DownLoadIcon } from "assets/Common/icons/download.svg";
// import { ReactComponent as MemberVipIcon } from "assets/Common/icons/icon-member-vip.svg";
import Deposit_Green from "assets/Mobile/icons/deposit.png";
import HistoryIconImg from "assets/Mobile/icons/historyImg.png";
import WithdrawIconImg from "assets/Mobile/icons/withdraw.png";
import { ReactComponent as HistoryIcon } from "assets/temporary/icons/history.svg";
import { ReactComponent as PromotionIcon } from "assets/temporary/icons/promos.svg";
import { ReactComponent as WithdrawIcon } from "assets/temporary/icons/withdraw.svg";

import { PATH } from "./path";

export interface HeaderMenuStyle {
  icon: any;
  label: string;
  link: string;
  iconImage?: string;
}

export const headerMenu: HeaderMenuStyle[] = [
  {
    icon: PromotionIcon,
    label: "Promos",
    link: PATH.Promotion,
  },
  { icon: DownLoadIcon, label: "Download", link: PATH.Download },
  // {
  //   icon: BrandSVG,
  //   label: "Affiliate",
  //   link: PATH.User.Referral,
  // },
  // {
  //   icon: MemberVipIcon,
  //   label: "VIP",
  //   link: PATH.User.myVip,
  // },
];

export const headerMenuMobile: HeaderMenuStyle[] = [
  // {
  //   icon: PromotionIcon,
  //   label: "Promos",
  //   link: PATH.Promotion,
  // },
  // {
  //   icon: BrandSVG,
  //   label: "Brand",
  //   link: PATH.Brand,
  // },
  {
    icon: Deposit_Green,
    label: "bottomtab:Deposit",
    link: PATH.Deposit,
    iconImage: Deposit_Green,
  },
  {
    icon: WithdrawIcon,
    label: "home:Withdraw",
    link: PATH.Withdraw,
    iconImage: WithdrawIconImg,
  },

  {
    icon: HistoryIcon,
    label: "home:History",
    link: PATH.User.TransactionHistory,
    iconImage: HistoryIconImg,
  },
];
