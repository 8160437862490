import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { PromotionType } from "./type";

export const getPromotion = {
  checkPromotionCode: (typename: string, payload: { username: string }) =>
    axiosRequest.post<PromotionType>(
      "/",
      payloadHelper("CheckPromotionWelcome180", typename, payload)
    ),
};
