/** @jsxImportSource @emotion/react */

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import avatar from "assets/temporary/images/avatar_default.png";
import { PATH } from "contants/path";
import { formatNumberWithCommas } from "helpers/formatNumber";
import { useAppSelector } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectBalance, selectBalanceLoading, selectProfile } from "store/user";

import { HeaderAuthStyle } from "./style.index";

interface Props {
  visibleMenu: string;
  colorText: string;
}

const HeaderAuth = ({ visibleMenu, colorText }: Props) => {
  const { t } = useTranslation(["header", "currency"]);

  const profileUser = useAppSelector(selectProfile);
  const balanceUser = useAppSelector(selectBalance);
  const isLoading = useAppSelector(selectBalanceLoading);
  const navigate = useNavigate();
  return (
    <div css={HeaderAuthStyle.wrapper}>
      <div css={HeaderAuthStyle.infoHeader}>
        <h4 css={HeaderAuthStyle.title(visibleMenu, colorText)}>
          {profileUser && profileUser.username}
        </h4>

        <div css={HeaderAuthStyle.points(visibleMenu, colorText)}>
          <p>
            {t("currency:AUD")}{" "}
            {isLoading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 12, color: "#298548" }}
                    spin
                  />
                }
              />
            ) : (
              <> {formatNumberWithCommas(balanceUser?.total || "0.00")}</>
            )}
          </p>
        </div>
      </div>
      <img
        src={avatar}
        alt=""
        css={HeaderAuthStyle.avatar}
        onClick={() => navigate(PATH.User.TransactionHistory)}
      />
    </div>
  );
};

export default HeaderAuth;
